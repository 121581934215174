import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import * as styles from './meeting-card.styles.ts'
import { UserMeetingModel } from 'api/user-meetings/models/user-meeting.model.ts'
import { meetingMoodsStore } from 'store/meeting-moods.store.ts'
import { meetingCategoriesStore } from 'store/meeting-categories.store.ts'
import LocationSvg from 'assets/location.svg'
import { getFormatDateMessage, getFormatTimeMessage } from 'utils/date.tsx'
import { openYandexMapOrg } from 'utils/yandex.ts'
import { MEETING_STATUS_DATA_MAP, MeetingStatus } from 'const/statuses.ts'
import { SanekRoute } from 'router/router.enums.ts'
import { MEETING_TIME_TEMPLATE } from 'const/date.const.ts'
import { TimeslotModel } from 'api/models/timeslot.model.ts'
import { pluralize, TPluralizeConfig } from 'utils/pluralize.ts'

const DECLENSION_WORDS: TPluralizeConfig = [
  'вариант времени',
  'варианта времени',
  'вариантов времени',
]

type TMeetingCardProps = {
  meeting: UserMeetingModel
}

const getTimeMessage = (
  firstTimeslot: TimeslotModel,
  timeslotsCount: number,
  confirmedTimeslot: TimeslotModel | null
) => {
  if (confirmedTimeslot) {
    return getFormatTimeMessage(
      dayjs(confirmedTimeslot.timeFrom, MEETING_TIME_TEMPLATE),
      dayjs(confirmedTimeslot.timeTo, MEETING_TIME_TEMPLATE)
    )
  }

  if (timeslotsCount > 1) {
    return `${timeslotsCount} ${pluralize(DECLENSION_WORDS, timeslotsCount)}`
  } else {
    return getFormatTimeMessage(
      dayjs(firstTimeslot.timeFrom, MEETING_TIME_TEMPLATE),
      dayjs(firstTimeslot.timeTo, MEETING_TIME_TEMPLATE)
    )
  }
}

export const MeetingCard: React.FC<TMeetingCardProps> = ({ meeting }) => {
  const navigate = useNavigate()
  const { moods, categories } = {
    ...meetingMoodsStore,
    ...meetingCategoriesStore,
  }
  const timeslotsCount = meeting.timeslots.length
  const firstTimeslot = meeting.timeslots[0]
  const timeMessage = getTimeMessage(
    firstTimeslot,
    timeslotsCount,
    meeting.confirmedTimeslot
  )
  const dateMessage = getFormatDateMessage(
    meeting.confirmedTimeslot
      ? dayjs(meeting.confirmedTimeslot.date)
      : dayjs(firstTimeslot.date)
  )
  const moodImgUrl = moods.find(({ code }) => code === meeting.mood.code)?.image
    ?.url
  const categoryImgUrl = categories.find(
    ({ code }) => code === meeting.category.code
  )?.image?.url

  const meetingRole = meeting.isCreator ? 'creator' : 'invited'
  const statusInfo = MEETING_STATUS_DATA_MAP[meetingRole][meeting.status]
  const statusText = useMemo(() => {
    if (
      meeting.status === MeetingStatus.WAIT_CONFIRMATION &&
      meeting.isConfirmed
    ) {
      return 'Ждём подтверждения друга'
    }
    return statusInfo.text
  }, [meeting])

  const handleOpenMapClick = (event: React.MouseEvent): void => {
    event.stopPropagation()
    openYandexMapOrg(meeting.place.yandexOrgId)
  }

  const handleCardClick = () => {
    navigate(`${SanekRoute.MEETING_DETAILS}/${meeting.id}`)
  }
  console.log(meeting)

  return (
    <div className={styles.container} onClick={handleCardClick}>
      <span className={styles.status} style={{ color: statusInfo.color }}>
        {statusText}
      </span>

      <div className={styles.card}>
        <div className={styles.block}>
          <span
            className={
              timeslotsCount > 1 && !meeting.confirmedTimeslot
                ? styles.timePlural
                : styles.time
            }
          >
            {timeMessage}
          </span>
          {(timeslotsCount === 1 || meeting.confirmedTimeslot) && (
            <span className={styles.date}>{dateMessage}</span>
          )}

          <div className={styles.emojiBlock}>
            <img
              className={styles.icon}
              src={moodImgUrl}
              alt={meeting.mood.code}
            />
            <img
              className={styles.icon}
              src={categoryImgUrl}
              alt={meeting.mood.code}
            />
          </div>
        </div>
        <div className={styles.placeBlock}>
          <div className={styles.placeTitle}>{meeting.place.name}</div>
          <div>
            <span className={styles.address}>{meeting.place.address}</span>

            <p className={styles.metro}>
              <span style={{ color: `${meeting.place.metroStation.color}` }}>
                M
              </span>
              {meeting.place.metroStation.name}
            </p>
          </div>
        </div>

        <div className={styles.placeMarkWrapper}>
          <div className={styles.placeMark}>
            <img
              src={LocationSvg}
              alt={meeting.mood.code}
              onClick={(event) => handleOpenMapClick(event)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
