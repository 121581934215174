import * as styles from './phone.page.style.ts'

import { Checkbox } from 'antd-mobile'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'

import { observer } from 'mobx-react-lite'
import { JSX, useEffect, useState } from 'react'
import { router } from 'router'

import { SanekRoute } from 'router/router.enums.ts'
import { userStore } from 'store/user.store.ts'
import { SanekButton } from 'ui/sanek-button'

import { PhoneCodeInput } from 'ui/phone-code-input'
import { PhoneNumberInput } from 'ui/phone-number-input'

import { AppService } from 'api/services/app.service.ts'
import { smsTransport } from 'api/sms.transport.ts'

import CheckedIcon from 'assets/checked.svg'
import ManSMSIntro from 'assets/man_sms_intro.png'
import UnCheckedIcon from 'assets/unchecked.svg'
import { CenteredLoader } from 'components/common/centered-loader'

const validPhoneMaskLength = 18
const validSMSCodeLength = 4

const ERROR_MAP = {
  'Too many requests': 'Слишком много попыток авторизации, попробуйте позже',
}

export const RegisterPhonePage = observer((): JSX.Element => {
  const { isLoading: isLoadingUser } = userStore

  const [hasAgreed, setHasAgreed] = useState(true)
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('')
  const [seconds, setSeconds] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showCodeInput, setIsShowCodeInput] = useState(false)
  const [deviceId, setDeviceId] = useState<string | null>(null)

  const isValidPhoneNumber = phone.length === validPhoneMaskLength
  const isValidSMSCodeLength = code.length === validSMSCodeLength

  useEffect(() => {
    if (!seconds) return

    const intervalId = setInterval(() => {
      const nextValue = seconds - 1
      setSeconds(nextValue)
      if (nextValue === 0) {
        clearInterval(intervalId)
      }
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [seconds])

  useEffect(() => {
    setError(false)
    setErrorText('')
  }, [code, phone])

  const sendSMSCode = async () => {
    setErrorText('')
    setIsLoading(true)

    try {
      const preparedPhone = phone.replace(/[^0-9\\.]+/g, '')
      const response = await smsTransport.sendSMSCode(preparedPhone)

      const {
        data: { validTo, uuid },
      } = response

      const date = new Date()

      const codeValidInSeconds = dayjs(validTo).diff(dayjs(date), 'seconds')
      setSeconds(codeValidInSeconds)
      setDeviceId(uuid)
      setIsShowCodeInput(true)
    } catch (e) {
      if (e instanceof AxiosError) {
        setErrorText(e.response?.data)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handlePhoneChange = (newPhone: string) => {
    setCode('')
    setIsShowCodeInput(false)
    setPhone(newPhone)
  }

  const handleCheck = (checked: boolean) => {
    setHasAgreed(checked)
  }

  const changePhoneNumber = () => {
    setPhone('')
    setIsShowCodeInput(false)
  }

  const auth = async () => {
    if (!deviceId || !code) return

    try {
      setError(false)
      setIsLoading(true)
      await userStore.smsCodeVerify({ code, uuid: deviceId })
      AppService.initStoreData()
      await userStore.getMe()

      void router.navigate(SanekRoute.ROOT)
    } catch (e) {
      setError(true)
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoadingUser) {
    return <CenteredLoader />
  }

  return (
    <div className={styles.pageContainer}>
      <h1>Санёк зовёт</h1>
      <div className={styles.description}>Введи номер телефона</div>
      <div className={styles.inputWrapper}>
        <PhoneNumberInput value={phone} onChange={handlePhoneChange} />
        {errorText && (
          <div className={styles.errorText}>
            {/*//@ts-ignore*/}
            {ERROR_MAP[errorText] || 'Что-то пошло не так'}
          </div>
        )}
      </div>
      {!showCodeInput && (
        <div className={styles.agreement}>
          <Checkbox
            checked={hasAgreed}
            onChange={handleCheck}
            icon={(checked) => {
              return checked ? (
                <img src={CheckedIcon} alt="+" />
              ) : (
                <img src={UnCheckedIcon} alt="+" />
              )
            }}
          >
            <div className={styles.agreementText}>
              Даю согласие на получение СМС рекламного и информационного
              характера
            </div>
          </Checkbox>
        </div>
      )}
      {showCodeInput && (
        <div className={styles.action} onClick={changePhoneNumber}>
          <span className={styles.text}>Изменить номер телефона</span>
        </div>
      )}
      {!showCodeInput && (
        <div className={styles.sendPhoneButton}>
          <SanekButton
            disabled={!isValidPhoneNumber || !hasAgreed}
            onClick={sendSMSCode}
            isLoading={isLoading}
          >
            Получить код
          </SanekButton>
        </div>
      )}
      {showCodeInput && (
        <>
          <div className={styles.description}>
            <div>Введи проверочный код из СМС</div>
          </div>
          <div className={styles.smsCodeForm}>
            <PhoneCodeInput value={code} onChange={setCode} />
          </div>
          {seconds && seconds > 0 ? (
            <div className={styles.hint}>
              Запросить повторно код можно через <br /> {seconds} сек
            </div>
          ) : (
            <div className={styles.action} onClick={sendSMSCode}>
              <span className={styles.text}>Выслать код повторно</span>
            </div>
          )}

          <div className={styles.loginButton}>
            <SanekButton
              disabled={!isValidSMSCodeLength}
              isLoading={isLoading}
              onClick={auth}
            >
              Войти
            </SanekButton>
          </div>
          {error && (
            <div className={styles.errorText}>
              Пожалуйста, введите правильный код
            </div>
          )}
        </>
      )}
      <img
        className={styles.man}
        src={ManSMSIntro}
        alt="Санёк"
        width="204px"
        height="260px"
      />
    </div>
  )
})
