import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { SanekRoute } from 'router/router.enums.ts'

import { CenteredLoader } from 'components/common/centered-loader'

import { NotificationModel } from 'api/notifications/model/notificationModel.ts'
import { notificationsStore } from 'store/notifications.store.ts'

import { notificationsTransport } from 'api/notifications/notifications.transport.ts'

export const NotificationByIdPage: FC = observer(() => {
  const { notifications, getNotifications, setNotifications, isLoading } =
    notificationsStore

  const navigate = useNavigate()

  const { id } = useParams<{ id?: string }>()

  const foundNotification = notifications.find(
    (notification) => notification.id === Number(id)
  )

  const navigateToEntityByNotification = (notification: NotificationModel) => {
    if (notification.entity === 'meeting') {
      return navigate(`${SanekRoute.MEETING_DETAILS}/${notification.entityId}`)
    }

    if (notification.entity === 'invitation') {
      return navigate(SanekRoute.INVITATIONS_LIST)
    }

    return navigate(SanekRoute.MAIN)
  }

  const resolveNotification = () => {
    if (!foundNotification) return

    const newNotifications = notifications.map((notification) => {
      return notification.id === foundNotification.id
        ? { ...notification, seen: true }
        : notification
    })

    setNotifications(newNotifications)

    navigateToEntityByNotification(foundNotification)
  }

  useEffect(() => {
    getNotifications()
  }, [])

  useEffect(() => {
    if (!foundNotification) {
      return
    }

    notificationsTransport
      .markNotificationAsRead(foundNotification.id)
      .then(resolveNotification)
      .catch(() => {
        navigate(SanekRoute.NOTIFICATIONS)
      })
  }, [foundNotification])

  if (!id) {
    return <Navigate to="/notifications" />
  }

  if (isLoading) {
    return <CenteredLoader />
  }

  return <CenteredLoader />
})
